export type NewsletterContactFormSource = 'MC_homepage' | 'mc_recipelibrary'

export type NewsletterContactForm = {
  email: string
  name: string
  source: NewsletterContactFormSource
}

export const createNewsletterContact = async (
  newsletterContactForm: NewsletterContactForm
) => {
  const response = await fetch('/api/signup', {
    method: 'POST',
    body: JSON.stringify(newsletterContactForm),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status !== 200) return Promise.reject()
}
