import React, { ReactElement } from 'react'
import { useSwiperSlide } from 'swiper/react'

import {
  Box,
  Image,
  Text,
  Flex,
  MultilineTruncatedText,
  theme,
} from '@mindfulchefuk/design-system'
import { Roundel } from '@mindfulchefuk/design-system/Roundel/Roundel'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { macroRounding } from '@mindfulchefuk/utils/macroRounding'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

import { RecipeStat } from '@mindfulchefuk/features/Onboarding/components/RecipeStats/RecipeStat'

type TWeeklyRecipe = {
  title: string
  imageUrl: string
  isVegan: boolean
  calories: number
  cookingTime: number
}

export const WeeklyRecipeCard = ({
  title,
  imageUrl,
  isVegan,
  calories,
  cookingTime,
}: TWeeklyRecipe): ReactElement => {
  const slide = useSwiperSlide()

  return (
    <Box py={12}>
      <Box
        width={{ base: 192, sm: 240 }}
        boxShadow={slide.isVisible ? 'standard' : 'zero'}
        onClick={() =>
          logEvent({
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Recipe Carousel Recipes clicked',
            label: title,
          })
        }
      >
        <Box position="relative">
          <Image
            useFallback
            loading="eager"
            alt={title}
            src={imageUrl}
            width={360}
            height={250}
          />
          {isVegan && <VeganBadge />}
        </Box>

        <Box pt={12} pb={4} backgroundColor="white">
          <MultilineTruncatedText
            height={48}
            lines={2}
            variant="heading-xs"
            textAlign="center"
            mx={{ base: 8, md: 16 }}
          >
            {title}
          </MultilineTruncatedText>

          <Flex justify="center">
            <RecipeStat icon="calories">
              {macroRounding.energy(calories)} kcal
            </RecipeStat>
            <RecipeStat icon="clock">{cookingTime} min</RecipeStat>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

const VeganBadge = (): ReactElement => (
  <Box position="absolute" bottom={-12} left={8}>
    <Roundel color={theme.colors['vegan-green']} filled size={30}>
      <Text variant="heading-xxs" color="white">
        Vg
      </Text>
    </Roundel>
  </Box>
)
